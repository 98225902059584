import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import propTypes from 'prop-types';
import React, { useState, useRef } from 'react';

import Layout from '../layouts/Layout';
import { articleFactory } from '../services/articleFactory';

import styles from './product.module.css';

export const ArticlePageTemplate = ({
  name,
  description,
  metaDescription,
  metaKeywords,
  html,
}) => {
  return (
    <Layout
      title={name}
      description={description}
      metaDescription={metaDescription}
      metaKeywords={metaKeywords}
    >
      <div className="w-full flex flex-col items-center md:flex-row md:items-start">
        <div className="max-w-xl mx-auto md:mt-16 md:items-start">
          <div className="w-full items-baseline justify-between my-6 lg:mt-0 lg:mb-10">
            <h1 className="text-black text-2xl sm:text-4xl font-extrabold lg:text-5xl leading-none">
              {name}
            </h1>
          </div>
{/*           {image ? (
            image.childImageSharp ? (
              <Img
                className={`bg-white rounded-md w-full mx-auto md:w-100 ${styles.customBorder}`}
                fluid={image.childImageSharp.fluid}
                alt={name}
              />
            ) : (
              <img src={image} alt={name} className={`w-full md:w-100 ${styles.customBorder}`} />
            )
          ) : (
            <></>
          )} */}
          <div className="w-full my-8" dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </div>
    </Layout>
  );
};

const ArticlePage = ({ data: { markdownRemark } }) => {
  const article = articleFactory(markdownRemark);
  return (
    <ArticlePageTemplate
      name={article.name}
      description={article.description}
      metaDescription={article.metaDescription}
      metaKeywords={article.metaKeywords}
      html={article.html}
    />
  );
};

export const query = graphql`
  query ArticleById($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        metaDescription
        metaKeywords
        description
        name
        price
        url
        bank
        seller {
          id
          name
          fields {
            slug
          }
        }
        tags {
          id
          name
          fields {
            slug
          }
        }
        category {
          id
          name
          fields {
            slug
          }
        }
      }
    }
  }
`;

ArticlePageTemplate.propTypes = {
  name: propTypes.string.isRequired,
  metaDescription: propTypes.string.isRequired,
  metaKeywords: propTypes.string.isRequired,
};

export default ArticlePage;
