const articleFactory = article => ({
  id: article.id,
  image: article.frontmatter.image,
  name: article.frontmatter.name,
  metaDescription: article.frontmatter.metaDescription,
  metaKeywords: article.frontmatter.metaKeywords,
  slug: article.fields.slug,
  html: article.html
});

export { articleFactory };
